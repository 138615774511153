*, *:before, *:after {
  box-sizing: border-box !important;
}

@font-face {
  font-family: "Comic Sans MS";
  src: url("ComicSansMS.woff2") format("woff2"),
    url("ComicSansMS.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Comic Sans MS";
  src: url("ComicSansMS-Bold.woff2") format("woff2"),
    url("ComicSansMS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}

.app {
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
  padding: 8px 24px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  gap: 16px;
}

h1 {
  display: block;
  margin: 0;
  line-height: 1.5;
  font-size: 40px;
  text-align: center;
  font-weight: 400;
  font-family: "Comic Sans MS", "Comic Sans", "Papyrus", sans-serif;
  color: #000000;
}

.title-mood {
  display: inline-block;
  font-weight: 800;

  background: #000000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: 0px 0px #00000000;
}

.title-mood.jaymo {
  background: #664d4d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-mood.depressed {
  background: #4256c1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-mood.normal {
  background: #000000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-mood.alex {
  background: linear-gradient(
    90deg,
    #ea5454 0%,
    #fbff3f 22.4%,
    #6aee8f 46.35%,
    #1f69fe 70.83%,
    #7c3fff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-mood.happy {
  background: #7de16d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bar {
  position: relative;
  width: 100%;
  height: 48px;
  margin: 24px auto 48px auto;
  border-radius: 48px;
  border: 1px solid black;
  overflow: hidden;
}

.bar-progress {
  height: 48px;
  border-radius: 48px;
}

.full-body {
  position: relative;
  width: 80%;
  margin: 0 auto 24px auto;
  text-align: center;
}

.body {
  position: relative;
  top: 0;
  width: 95%;
}

.face {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 5;

  width: 73.2%;
  height: auto;
  margin: 0 auto;
  text-align: center;

  transform: translateX(-50%);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.buttons div {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
}

.buttons div img {
  width: 100%;
  height: 100%;
}

.buttons-red {
  background: #ea5454;
}

.buttons-yellow {
  background: #fbff3f;
}

.buttons-green {
  background: #6aee8f;
}

.buttons-blue {
  background: #3074ff;
}

.buttons-purple {
  background: #7c3fff;
}
